import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import bellwoods from "../apis/bellwoods";
import "../css/PolicyPage.css";
import qs from "qs";
import { v4 as uuid } from "uuid";

const query = qs.stringify({
  populate: "*",
});

const PolicyLinks = ({ onClick }) => {
  const [links, setLinks] = useState([]);
  useEffect(() => {
    bellwoods
      .get(`/api/policies?${query}`)
      .then((response) => {
        setLinks(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {links.map((el) => {
        return (
          <Link key={uuid()} className="privacy-policy zoom banks" to={`/policy${el.attributes.slug}`} onClick={onClick}>
            {el.attributes.text || "policy"}
          </Link>
        );
      })}
    </>
  );
};

export default PolicyLinks;
