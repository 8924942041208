import React, { useState, useEffect } from "react";

const CurrentUser = () => {
  const [firstName, setfirstName] = useState("");

  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      setfirstName(localStorage.getItem("firstName"));
    }
  }, []);

  return <span className="banks">{firstName}</span>;
};

export default CurrentUser;
