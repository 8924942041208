import React from "react";
import "../css/Socials.css";

const Socials = ({ ig, fb, li, em, ph }) => {
  return (
    <div className="text-center">
      <a
        href="https://www.instagram.com/bellwoodsweb/"
        target="_blank"
        rel="noreferrer"
        className={ig ? "" : "d-none"}
      >
        <i className="fab fa-instagram-square navicon zoom"></i>
      </a>
      <a
        href="https://www.facebook.com/bellwoodsweb"
        target="_blank"
        rel="noreferrer"
        className={fb ? "" : "d-none"}
      >
        <i className="fab fa-facebook-square navicon zoom"></i>
      </a>
      <a
        href="https://www.linkedin.com/company/72282207/"
        target="_blank"
        rel="noreferrer"
        className={li ? "" : "d-none"}
      >
        <i className="fab fa-linkedin navicon zoom"></i>
      </a>
      <a href="/contact" className={em ? "" : "d-none"}>
        <i className="fas fa-envelope-square navicon zoom"></i>
      </a>
      <a href="tel:289-253-7312" className={ph ? "" : "d-none"}>
        <i className="fas fa-phone-square navicon zoom"></i>
      </a>
    </div>
  );
};

export default Socials;
