import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const site = "Bellwoods Web";

const description = {
  landing: "Cost savings & operational excellence through enterprise software consulting, implementation, and support!",
  contact: "Get in touch with a team member at Bellwoods Web to find out more about our consulting services!",
  portfolio: "A few portfolio pieces and case studies that we're proud of!",
  blog: "Learn more about the latest trends in enterprise software and how they can help your business!",
  policies: "Bellwoods Web's policies on privacy, cookies, and terms of service.",
  promo: "Bellwoods Web is offering a free discovery call to help you get started with your next project!",
};

function Head() {
  const { id } = useParams();

  //   Depending on the page we're on, we'll want different head tags. make a 'case' for each page slug
  switch (window.location.pathname) {
    case "/":
      return (
        <Helmet>
          <title>{site}</title>
          <meta name="description" content={description.landing} />
          <meta property="og:description" content={description.landing} />
          <meta property="twitter:description" content={description.landing} />
          <meta property="og:description" content={description.landing} />
          <meta property="twitter:description" content={description.landing} />
        </Helmet>
      );

    case "/promo":
      return (
        <Helmet>
          <title>{site}</title>
          <meta name="description" content={description.promo} />
          <meta property="og:description" content={description.promo} />
          <meta property="twitter:description" content={description.promo} />
          <meta property="og:description" content={description.promo} />
          <meta property="twitter:description" content={description.promo} />
        </Helmet>
      );

    case "/contact":
      return (
        <Helmet>
          <title>{site} | Contact</title>
          <meta name="description" content={description.contact} />
          <meta property="og:description" content={description.contact} />
          <meta property="twitter:description" content={description.contact} />
          <meta property="og:description" content={description.contact} />
          <meta property="twitter:description" content={description.contact} />
        </Helmet>
      );

    case "/portfolio":
      return (
        <Helmet>
          <title>{site} | Portfolio</title>
          <meta name="description" content={description.portfolio} />
          <meta property="og:description" content={description.portfolio} />
          <meta property="twitter:description" content={description.portfolio} />
          <meta property="og:description" content={description.portfolio} />
          <meta property="twitter:description" content={description.portfolio} />
        </Helmet>
      );

    case "/blog":
      return (
        <Helmet>
          <title>{site} | Blog</title>
          <meta name="description" content={description.blog} />
          <meta property="og:description" content={description.blog} />
          <meta property="twitter:description" content={description.blog} />
          <meta property="og:description" content={description.blog} />
          <meta property="twitter:description" content={description.blog} />
        </Helmet>
      );

    case `/blog/${id}`:
      return (
        <Helmet>
          <title>{site} | Blog</title>
          <meta name="description" content={description.blog} />
          <meta property="og:description" content={description.blog} />
          <meta property="twitter:description" content={description.blog} />
          <meta property="og:description" content={description.blog} />
          <meta property="twitter:description" content={description.blog} />
        </Helmet>
      );

    case `/policy/${id}`:
      return (
        <Helmet>
          <title>{site} | Policy</title>
          <meta name="description" content={description.policy} />
          <meta property="og:description" content={description.policy} />
          <meta property="twitter:description" content={description.policy} />
          <meta property="og:description" content={description.policy} />
          <meta property="twitter:description" content={description.policy} />
        </Helmet>
      );

    case "/login":
      return (
        <Helmet>
          <title>{site} | Login</title>
          <meta name="robots" content="noindex"></meta>
        </Helmet>
      );

    case "/register":
      return (
        <Helmet>
          <title>{site} | Register</title>
          <meta name="robots" content="noindex"></meta>
        </Helmet>
      );

    default:
      return (
        <Helmet>
          <title>${site}</title>
        </Helmet>
      );
  }
}

export default Head;
