import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PAGENUM = 5;
const Page5 = ({ errors, onValuesChange, state }) => {
  const { page, ecommerce } = state;
  return (
    <div
      className="quote-page"
      style={
        page === PAGENUM
          ? { maxHeight: "1000vh" }
          : { maxHeight: "0", height: "0" }
      }
    >
      <Row>
        <Col xs={{ span: 12, offset: 0 }} md={{ span: 4, offset: 4 }}>
          {/* ecommerce */}

          <div className="grouped fields">
            <label className="banks" htmlFor="ecommerce">
              do you plan on selling items on your site:
            </label>
            <div className="field">
              <div className="ui radio bodini">
                <label>
                  <input
                    type="radio"
                    name="ecommerce"
                    value="yes"
                    checked={ecommerce === "yes"}
                    onChange={(e) => onValuesChange(e, "ecommerce", PAGENUM, 0)}
                  />
                  <span className="ml-3">yes</span>
                </label>
              </div>
            </div>

            <div className="field">
              <div className="ui radio bodini">
                <label>
                  <input
                    type="radio"
                    name="ecommerce"
                    value="no"
                    checked={ecommerce === "no"}
                    onChange={(e) => onValuesChange(e, "ecommerce", PAGENUM, 0)}
                  />
                  <span className="ml-3">no</span>
                </label>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 12, offset: 0 }} md={{ span: 4, offset: 4 }}>
          {/* categories */}

          <div className="field bodini">
            <label className="banks">what do you want to sell:</label>
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="products"
                value="yes"
                onChange={(e) => onValuesChange(e, "products", PAGENUM, 1)}
              />
              <label>products & services</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="giftcards"
                value="yes"
                onChange={(e) => onValuesChange(e, "giftcards", PAGENUM, 2)}
              />
              <label>gift cards</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="tickets"
                value="yes"
                onChange={(e) => onValuesChange(e, "tickets", PAGENUM, 3)}
              />
              <label>event tickets</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="donations"
                value="yes"
                onChange={(e) => onValuesChange(e, "donations", PAGENUM, 4)}
              />
              <label>accept donations</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="invoices"
                value="yes"
                onChange={(e) => onValuesChange(e, "invoices", PAGENUM, 5)}
              />
              <label>payments for invoices</label>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Page5;
