import React, { Component } from "react";
import { Link } from "react-router-dom";

import { elastic as Menu } from "react-burger-menu";
import "../css/Navigation.css";
import Socials from "./Socials";
import PolicyLinks from "./PolicyLinks";

export default class Navigation extends Component {
  // state = { open: true };
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }
  render() {
    return (
      <div>
        <div className="menu-circle"></div>
        <Menu right isOpen={this.state.menuOpen} onStateChange={(state) => this.handleStateChange(state)}>
          <Link className="menu-item nav-link zoom banks" to="/" onClick={() => this.closeMenu()}>
            Home
          </Link>
          <Link className="menu-item nav-link zoom banks" to="/contact" onClick={() => this.closeMenu()}>
            Contact
          </Link>
          <Link className="menu-item nav-link zoom banks" to="/portfolio" onClick={() => this.closeMenu()}>
            Portfolio
          </Link>
          <Link className="menu-item nav-link zoom banks" to="/blog" onClick={() => this.closeMenu()}>
            Blog
          </Link>
          <Link className="nav-link" to="/" onClick={() => this.closeMenu()}>
            <img src="/images/logos/10.png" className="nav-logo zoom" alt="React Bootstrap logo" />
          </Link>
          <Socials ig fb li />

          <PolicyLinks onClick={() => this.closeMenu()} />
        </Menu>
      </div>
    );
  }
}
