import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
import React, { useEffect, useState } from "react";
import bellwoods from "../apis/bellwoods";
import qs from "qs";

const query = qs.stringify({
  populate: {
    logo: {
      populate: "*",
    },
  },
});

const options = {
  type: "loop",
  gap: "10px",
  drag: "free",
  arrows: false,
  pagination: false,
  perPage: 8,

  breakpoints: {
    800: {
      perPage: 6,
    },
    500: {
      perPage: 4,
    },
  },
  autoScroll: {
    pauseOnHover: false,
    pauseOnFocus: false,
    rewind: false,
    speed: 1,
  },
};

export default function Clients() {
  const [clients, setClients] = useState([]);
  useEffect(() => {
    bellwoods
      .get(`/api/clients?${query}`)
      .then((response) => {
        const chunk1 = response.data.data.slice(0, response.data.data.length / 2);
        const chunk2 = response.data.data.slice(response.data.data.length / 2, response.data.data.length);
        setClients([chunk1, chunk2]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!clients?.length) return null;

  return (
    <div className="clients">
      <h2 className="banks bw-blue-text">Some of our clients.</h2>
      <Splide options={options} extensions={{ AutoScroll }}>
        {[...clients[0], ...clients[1]].map((client, i) => {
          return (
            <SplideSlide key={"client" + i}>
              <img src={client?.attributes?.logo?.data?.attributes?.url} />
            </SplideSlide>
          );
        })}
      </Splide>
      <Splide options={options} extensions={{ AutoScroll }}>
        {[...clients[1], ...clients[0]].map((client, i) => {
          return (
            <SplideSlide key={"client" + i}>
              <img src={client?.attributes?.logo?.data?.attributes?.url} />
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
}
