import React from "react";

import { useAuthFunctions } from "../../hooks/useAuthFunctions";
import { Link, useLocation } from "react-router-dom";
import Banner from "../Banner";

const QuickbooksEmbedAuthPage = (props) => {
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const state = query.get("q") || "login";

  const renderForm = () => {
    switch (state) {
      case "signup":
        return <SignupForm />;
      case "login":
        return <LoginForm />;
      case "forgot password":
        return <ForgotPasswordForm />;
      default:
        return <h1>Error</h1>;
    }
  };

  return (
    <div className="page">
      <Banner />
      {renderForm()}
    </div>
  );
};

export default QuickbooksEmbedAuthPage;

const SignupForm = () => {
  const { handleSignup } = useAuthFunctions();
  return (
    <form className="get-in-touch-form" onSubmit={handleSignup}>
      <label htmlFor="name">Name</label>
      <input type="text" className="text-input w-input" maxLength={256} name="name" placeholder="Enter your name" />
      <label htmlFor="phone">Phone</label>
      <input type="tel" className="text-input w-input" maxLength={256} name="phone" placeholder="Enter your phone" />
      <label htmlFor="email">Email</label>
      <input type="email" className="text-input w-input" maxLength={256} name="email" placeholder="Enter your email" required />
      <label htmlFor="password">Password</label>
      <input type="password" className="text-input w-input" maxLength={256} name="password" placeholder="Enter your password" required />
      <input type="submit" defaultValue="Signup" className="button purple w-button" />
      <div className="div-block-17">
        <Link to="/quickbooks/embed/auth?q=login" className="link dark">
          Already have an account?
        </Link>
      </div>
    </form>
  );
};

export const LoginForm = () => {
  const { handleLogin } = useAuthFunctions();
  return (
    <form className="get-in-touch-form" onSubmit={handleLogin}>
      <label htmlFor="email">Email</label>
      <input type="email" className="text-input w-input" maxLength={256} name="email" placeholder="Enter your email" required />
      <label htmlFor="password">Password</label>
      <input type="password" className="text-input w-input" maxLength={256} name="password" placeholder="Enter your password" required />
      <input type="submit" className="button purple w-button" />
      <div className="div-block-17">
        <Link to="/quickbooks/embed/auth?q=signup" className="link dark">
          Need an account?
        </Link>

        <Link to="/quickbooks/embed/auth?q=forgot password" className="link dark">
          Forgot Password?
        </Link>
      </div>
    </form>
  );
};

export const ForgotPasswordForm = () => {
  const { handleForgotPassword } = useAuthFunctions();
  return (
    <form onSubmit={handleForgotPassword} className="get-in-touch-form">
      <label htmlFor="email">Email</label>
      <input type="email" className="text-input w-input" maxLength={256} name="email" placeholder="Enter your email" required />
      <input type="submit" className="button purple w-button" />
      <div className="div-block-17">
        <Link to="/quickbooks/embed/auth?q=login" className="link dark">
          Login
        </Link>
        <Link to="/quickbooks/embed/auth?q=signup" className="link dark">
          Signup
        </Link>
      </div>
    </form>
  );
};
