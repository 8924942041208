//

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loader from "../components/Loader";

import bellwoods from "../apis/bellwoods";
import formatDate from "../hooks/formatDate";
import "../css/Blog.css";
import Authenticate from "../components/Authenticate";
// import Likes from "../components/Likes";
// import CommentsIcon from "../components/CommentsIcon";
import qs from "qs";
import Page from "../components/Page";

const query = qs.stringify({
  populate: {
    cover: {
      populate: "*",
    },
    comment: {
      populate: "*",
    },
    like: {
      populate: "*",
    },
  },
});

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState(false);
  const [tags, setTags] = useState([]);
  const [filter, setFilter] = useState("all");

  //get all posts on component load
  useEffect(() => {
    bellwoods
      .get(`/api/posts?${query}`)
      .then((response) => {
        setPosts(sortPosts(response.data.data, order));
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  //set tags from posts
  useEffect(() => {
    const tagsTmp = [];
    posts &&
      posts.map((el) => {
        tagsTmp.push(el.attributes.tag);
        return true;
      });
    setTags([...new Set(tagsTmp)]);
  }, [posts]);

  //set order of posts
  useEffect(() => {
    setPosts(sortPosts(posts, order));
  }, [order]);

  //helper function to set chronological order of posts
  const sortPosts = (posts, order) => {
    return [...posts].sort(function (a, b) {
      if (!order) {
        return new Date(b.attributes.created) - new Date(a.attributes.created);
      } else {
        return new Date(a.attributes.created) - new Date(b.attributes.created);
      }
    });
  };

  return (
    <Page>
      <div className="blog">
        <div className="blog-header">
          <div className="ui container">
            <Authenticate redirect="/blog" />
            <h1 className="banks mb-4 blog-title">Bellwoods Blog</h1>
          </div>

          <div className="slide-btn btn btn-large bw-blue banks" onClick={() => setOrder(!order)}>
            {order ? "oldest to newest" : "newest to oldest"}
          </div>
          <span>
            <select className="custom-select banks bw-blue" onChange={(e) => setFilter(e.target.value)}>
              <option value="all">all posts</option>
              {tags.map((el, i) => {
                return (
                  <option key={el} value={el}>
                    {el}
                  </option>
                );
              })}
            </select>
          </span>
        </div>

        <div className="ui container blog-cards">
          {posts.length === 0 && !error && <Loader />}
          {error && <h2 className="banks text-center mt-4 mb-4">woops... something went wrong!</h2>}
          <Row>
            {posts &&
              posts.map((el, i) => {
                return <BlogCard key={i} id={el.id} data={el.attributes} filter={filter} />;
              })}
          </Row>
        </div>
      </div>
    </Page>
  );
};

const BlogCard = ({ id, data, filter }) => {
  const [age, setAge] = useState(false);

  useEffect(() => {
    var made = new Date(data.created);
    var today = new Date();
    var datediff = today.getTime() - made.getTime(); //Future date - current date
    var diff = Math.floor(datediff / (1000 * 60 * 60 * 24));
    setAge(diff);
  }, [data.created]);

  const { title, created, cover, content, tag } = data;

  if (filter === "all" || tag === filter) {
    return (
      <Col xs={{ span: 12, offset: 0 }} sm={{ span: 6, offset: 0 }} lg={{ span: 4, offset: 0 }} className="blog-card zoom">
        <Link to={`/blog/${id}`}>
          <Card className="shadow ">
            <Card.Img className="blog-card-img" variant="top" src={`${cover?.data?.attributes?.url}`} alt={cover?.data?.attributes?.alternativeText} />
            <Card.Body className="blog-card-body">
              <div className={`new banks bw-blue ${age > 7 && "d-none"}`}>new!</div>

              <Card.Title className="banks blog-card-title">{title}</Card.Title>
              <Card.Text className="banks blog-card-content">
                {formatDate(created)}
                <span className="blog-tag">{tag}</span>
              </Card.Text>
              <ReactMarkdown className="bodini blog-card-content">{`${content.substring(0, 110)}...`}</ReactMarkdown>
              {/* <hr></hr> */}
              {/* <Likes postId={id} /> */}
              {/* <CommentsIcon postId={id} /> */}
            </Card.Body>
          </Card>
        </Link>
      </Col>
    );
  } else {
    return null;
  }
};

export default Blog;
