import React from "react";
import bellwoods from "../apis/bellwoods";

class ContactEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      checkbox: false,
      errors: [false, false, false, false],
      mailSent: false,
      error: null,
    };
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      bellwoods
        .post(`/api/ezforms/submit`, {
          formName: "AdLandingPage",
          formData: {
            name: this.state.name,
            email: this.state.email,
            message: this.state.message,
          },
        })
        .then((result) => {
          this.setState({
            mailSent: true,
          });
          this.clearForm();
        })
        .catch((error) => {
          this.setState({ error: error.message });
          this.clearForm();
        });
    }
  };

  validate = () => {
    const { name, email, message, checkbox } = this.state;
    const tmpErr = [false, false, false, false];
    let result = true;

    if (!name) tmpErr[0] = true;
    if (!this.validateEmail(email)) tmpErr[1] = true;
    if (!message) tmpErr[2] = true;
    if (!checkbox) tmpErr[3] = true;
    this.setState({ errors: tmpErr });
    tmpErr.map((el) => {
      if (el) {
        result = false;
        return true;
      }
      return false;
    });
    return result;
  };

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onNameChange = (e) => {
    this.setState({ name: e.target.value });
    this.setError(e.target.value, 0);
  };

  onEmailChange = (e) => {
    this.setState({ email: e.target.value });
    this.setError(e.target.value, 1);
  };

  onMessageChange = (e) => {
    this.setState({ message: e.target.value });
    this.setError(e.target.value, 2);
  };

  onCheckBoxChange = (e) => {
    this.setState({ checkbox: e.target.checked });
    this.setError(e.target.checked, 3);
  };

  setError = (value, index) => {
    const tmp = this.state.errors.map((el, i) => (i === index ? !value : el));
    this.setState({ errors: tmp });
  };

  clearForm = () => {
    this.setState({
      name: "",
      email: "",
      message: "",
      checkbox: false,
      errors: [false, false, false, false],
    });
  };

  render() {
    return (
      <div className="ui container">
        <h2 className="banks bw-blue-text ">Get In Touch</h2>
        {!this.state.mailSent && !this.state.error && (
          <form action="#" className={`noshaddow ui large form ${this.state.mailSent || this.state.error ? "contact-hidden" : ""}`}>
            <div className="two fields ">
              <div className={`field ${this.state.errors[0] ? "error" : ""}`}>
                <input type="text" id="name" name="name" placeholder="what's your name?" onChange={(e) => this.onNameChange(e)} value={this.state.name} className="bodini " />
              </div>

              <div className={`field ${this.state.errors[1] ? "error" : ""}`}>
                <input type="email" id="email" name="email" placeholder="what's your email?" onChange={(e) => this.onEmailChange(e)} value={this.state.email} className="bodini " />
              </div>
            </div>
            <div className="field ">
              <div className={`field ${this.state.errors[2] ? "error" : ""}`}>
                <textarea id="message" name="message" placeholder="what can we do for you?" onChange={(e) => this.onMessageChange(e)} value={this.state.message} className="bodini" rows="8" />
              </div>
            </div>

            <div className="field text-center">
              <button type="submit" name="submit" className="ui button bw-blue banks large send-button shadow" onClick={(e) => this.handleFormSubmit(e)}>
                send us a message
              </button>
            </div>

            <div className="field text-center ">
              <div className="ui toggle checkbox">
                <input type="checkbox" name="public" onChange={(e) => this.onCheckBoxChange(e)} className="shadow" />
                <label className={`coloring ${this.state.errors[3] ? "error" : ""}`}></label>
              </div>
              <p className="banks">i'm {`${this.state.checkbox ? "not" : ""}`} a robot</p>
            </div>
          </form>
        )}

        {this.state.mailSent && (
          <div className="form success noshaddow">
            <div>
              <h3 className="banks bw-blue-text">Thank you!</h3>
              <p className="banks">Your submission has been received!</p>
            </div>
          </div>
        )}
        {this.state.error && (
          <div className="form fail noshaddow">
            <div>
              <h3 className="banks bw-blue-text">Oops!</h3>
              <p className="banks">Something went wrong while submitting the form. Please email bellwoodsweb@gmail</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ContactEmail;
