import React from "react";
import Page from "../components/Page";
import Contact from "../components/Contact";
import FAQs from "../components/FAQs";

const ContactPage = () => {
  return (
    <Page>
      <Contact noHeightAdded />
      <FAQs />
    </Page>
  );
};

export default ContactPage;
