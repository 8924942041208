import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PAGENUM = 3;
const Page3 = ({ errors, onValuesChange, state }) => {
  const { page } = state;
  return (
    <div
      className="quote-page"
      style={
        page === PAGENUM
          ? { maxHeight: "1000vh" }
          : { maxHeight: "0", height: "0" }
      }
    >
      <div className="field">
        <label className="banks strong text-center">
          what pages do you want on your site:
        </label>
      </div>
      <Row>
        <Col xs={{ span: 8, offset: 4 }} md={{ span: 4, offset: 3 }}>
          {/* categories */}
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="blog"
                value="yes"
                onChange={(e) => onValuesChange(e, "blog", PAGENUM, 0)}
              />
              <label>blog</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="events"
                value="yes"
                onChange={(e) => onValuesChange(e, "events", PAGENUM, 1)}
              />
              <label>events</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="specials"
                value="yes"
                onChange={(e) => onValuesChange(e, "specials", PAGENUM, 2)}
              />
              <label>specials</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="faq"
                value="yes"
                onChange={(e) => onValuesChange(e, "faq", PAGENUM, 3)}
              />
              <label>FAQ's</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="staff"
                value="yes"
                onChange={(e) => onValuesChange(e, "staff", PAGENUM, 4)}
              />
              <label>staff members</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="news"
                value="yes"
                onChange={(e) => onValuesChange(e, "news", PAGENUM, 5)}
              />
              <label>news/press releases</label>
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 8, offset: 4 }}
          md={{ span: 4, offset: 0 }}
          className="mt-4 mt-md-0"
        >
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="portfolio"
                value="yes"
                onChange={(e) => onValuesChange(e, "portfolio", PAGENUM, 6)}
              />
              <label>portfolio</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="resources"
                value="yes"
                onChange={(e) => onValuesChange(e, "resources", PAGENUM, 7)}
              />
              <label>resources/document library</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="testimonials"
                value="yes"
                onChange={(e) => onValuesChange(e, "testimonials", PAGENUM, 8)}
              />
              <label>testimonials</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="projects"
                value="yes"
                onChange={(e) => onValuesChange(e, "projects", PAGENUM, 9)}
              />
              <label>projects</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="sponsors"
                value="yes"
                onChange={(e) => onValuesChange(e, "sponsors", PAGENUM, 10)}
              />
              <label>sponsors</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="careers"
                value="yes"
                onChange={(e) => onValuesChange(e, "careers", PAGENUM, 11)}
              />
              <label>careers/jobs</label>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Page3;
