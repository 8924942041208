import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Project from "../components/Project";
import "../css/Portfolio.css";
import bellwoods from "../apis/bellwoods";
import Loader from "../components/Loader";
import qs from "qs";
import Page from "../components/Page";
import Partner from "../components/Partner";
import PaginationButtons from "../components/PaginationButtons";

const query = qs.stringify({
  populate: "*",
});
const query2 = qs.stringify({
  populate: {
    cover: {
      populate: "*",
    },
  },
});

const Portfolio = () => {
  const [filter, setFilter] = useState("All");
  const [filterList, setFilterList] = useState([]);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [page, setPage] = useState(0);
  const [partnerships, setPartnerships] = useState([]);

  useEffect(() => {
    // get projects
    bellwoods
      .get(`/api/projects?${query}`)
      .then((response) => {
        console.log(response.data.data);
        setProjects(response.data?.data?.sort((a, b) => b.attributes.priority - a.attributes.priority));
        setFilteredProjects(response.data?.data?.sort((a, b) => b.attributes.priority - a.attributes.priority));
        // set filters
        let tmp = ["All"];
        for (const el of response.data?.data) {
          !tmp.includes(el.attributes.industry) && el.attributes.industry && tmp.push(el.attributes.industry);
        }
        setFilterList(tmp);
      })
      .catch((error) => {
        console.log(error);
      });

    bellwoods
      .get(`/api/partnerships?${query2}`)
      .then((response) => {
        setPartnerships(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (filter === "All") {
      setFilteredProjects(projects.sort((a, b) => b.attributes.priority - a.attributes.priority));
    } else {
      setFilteredProjects(projects.filter((el) => el.attributes.industry === filter).sort((a, b) => b.attributes.priority - a.attributes.priority));
    }
  }, [filter]);

  const renderProjects = (projects) => {
    return projects.map((el, i) => {
      return (
        <Col key={i} className="portfolio-col text-center" xs={{ span: 12, offset: 0 }} md={{ span: 4, offset: 0 }} lg={{ span: 3, offset: i % 3 ? 0 : 1 }}>
          <Project project={el.attributes} showText={true} />
        </Col>
      );
    });
  };

  if (filteredProjects.length === 0) {
    return <Loader />;
  } else {
    return (
      <Page>
        <div className="portfolio">
          <div className="portfolio-top">
            <div className="portfolio-background">
              <h1 className="banks text-center portfolio-title bw-blue-text ">Portfolio</h1>
              <div className="ui container portfolio-content">
                {/* <h2 className="banks mb-4 text-center blog-title bw-blue-text mt-4">Our Sites</h2> */}
                <div className="filterList">
                  {filterList.map((el, i) => {
                    return (
                      <button key={el} className={`ui button ${filter === el && "bw-blue"} banks  mt-2`} onClick={() => setFilter(el)}>
                        {el}
                      </button>
                    );
                  })}
                </div>

                <Row className="portfolio-row">{renderProjects(filteredProjects)}</Row>
                {/* <div style={{ marginBottom: 100 }}></div> */}

                {/* <PaginationButtons
                  onNext={() => {
                    setPage(page - 1);
                  }}
                  onBack={() => {}}
                /> */}

                {/* <h2 className="banks mb-4 text-center blog-title bw-blue-text ">Our Apps</h2> */}
                <Row>
                  {partnerships?.map((el, i) => {
                    return <Partner data={el} i={i} size={{ span: 12, offset: 0 }} />;
                  })}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
};

export default Portfolio;
