import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "beautiful-react-diagrams/styles.css";

import App from "./App";
import Modal from "./components/Modal";
import "./css/Fonts.css";
import "./css/App.css";
import "./css/ScreenSizing.css";

import authReducer from "./store/reducers/auth";
import quickbooksReducer from "./store/reducers/quickbooks";
import cmsReducer from "./store/reducers/cms";

import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  auth: authReducer,
  quickbooks: quickbooksReducer,
  cms: cmsReducer,
});
const store = configureStore({ reducer: rootReducer });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
// ReactDOM.render(<Modal />, document.getElementById("modal"));
