import React, { useState, useRef } from "react";
import "../css/AdLandingPage.css";
import LandingServices from "../components/LandingServices";
import WebflowPage from "../components/WebflowPage";
import { Link } from "react-router-dom";
import bellwoods from "../apis/bellwoods";

const AdLandingPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([false, false, false, false]);
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(null);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      bellwoods
        .post(`/api/ezforms/submit`, {
          formName: "AdLandingPage",
          formData: {
            name: name,
            email: email,
            phone: phone,
            message: message,
          },
        })
        .then((result) => {
          setMailSent(true);
          clearForm();
        })
        .catch((error) => {
          setError(error.message);
          clearForm();
        });
    }
  };

  const validate = () => {
    const tmpErr = [false, false, false, false];
    let result = true;

    if (!name) tmpErr[0] = true;
    if (!validateEmail(email)) tmpErr[1] = true;
    if (!phone) tmpErr[2] = true;
    if (!message) tmpErr[3] = true;

    setErrors(tmpErr);

    tmpErr.map((el) => {
      if (el) {
        result = false;
        return true;
      }
      return false;
    });
    return result;
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const onNameChange = (e) => {
    setName(e.target.value);
    handleSetError(e.target.value, 0);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
    handleSetError(e.target.value, 1);
  };
  const onPhoneChange = (e) => {
    setPhone(e.target.value);
    handleSetError(e.target.value, 2);
  };
  const onMessageChange = (e) => {
    setMessage(e.target.value);
    handleSetError(e.target.value, 3);
  };

  const handleSetError = (value, index) => {
    const tmp = errors.map((el, i) => (i === index ? !value : el));
    setErrors(tmp);
  };

  const clearForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };
  //   const resetForm = () => {
  //  setErrors([false, false, false, false]);
  //     setMailSent(false);
  //     setError(null);
  //   }

  const inputRef = useRef(null);

  return (
    <WebflowPage data-wf-page="64dbabafde00e567dd135412" data-wf-site="64dbabafde00e567dd13540e">
      <img src="images/3D-Items-1.png" loading="lazy" alt="" className="paralax-image _1" />
      <img src="images/1.png" loading="lazy" alt="" className="paralax-image _4" />
      <img src="images/6.png" loading="lazy" alt="" className="paralax-image _6" />
      <section data-w-id="e642b369-56ba-3f48-ece8-8d1d879a7f38" className="section ads">
        <div className="w-layout-blockcontainer container-2 w-container">
          <div className="div-block-3">
            <Link to="/">
              <img src="images/9.png" loading="lazy" id="w-node-d8fe71fe-dcb8-20d9-cf05-fe135599d5ff-dd135412" alt="" className="image" />
            </Link>
            <a href="tel:289-253-7312" className="submit-button phone w-button">
              (289) 253-7312
            </a>
          </div>
        </div>
        <div className="w-layout-blockcontainer container w-container">
          <div className="w-layout-grid grid">
            <div id="w-node-_9ca81146-8018-cb6b-05a9-382a8045a8a8-dd135412" className="div-block-4">
              <div className="text-block-2 banks">Enterprise Software Consulting</div>
              <h1 className="heading">Cut software costs & improve your operations</h1>
              <div className="div-block-5">
                <img src="images/3D-Items-1.png" loading="lazy" alt="" className="dot" />
                <div className="li banks">Discovery & Consultation</div>
              </div>
              <div className="div-block-5">
                <img src="images/3D-Items-1.png" loading="lazy" alt="" className="dot _2" />
                <div className="li banks">Solutions Implementation</div>
              </div>
              <div className="div-block-5">
                <img src="images/3D-Items-1.png" loading="lazy" alt="" className="dot _3" />
                <div className="li banks">Support, Training, & maintenance</div>
              </div>
              {/* <div className="div-block-5">
                <img src="images/3D-Items-1.png" loading="lazy" alt="" className="dot _4" />
                <div className="li banks">E-commerce and online payment integration.</div>
              </div> */}
            </div>
            <div id="w-node-_0589f703-70c4-8d5e-8a62-6ec5f0816c30-dd135412" className="div-block-2">
              <div className="form-block w-form">
                {!mailSent && !error && (
                  <form action="/" onSubmit={(e) => handleFormSubmit(e)} name="email-form" className="form">
                    <div className="div-block">
                      <div className="text-block banks">
                        15% <br />
                        Off
                      </div>
                    </div>
                    <h2 className="heading-2 banks">Get Started</h2>
                    <p className="paragraph bodini">Let's connect about your no commitment free discover call!</p>
                    <input
                      onChange={(e) => onNameChange(e)}
                      value={name}
                      type="text"
                      className={`banks text-field w-input ${errors[0] && "error"}`}
                      maxLength={256}
                      name="name"
                      data-name="Name"
                      placeholder="Name"
                      id="name"
                      ref={inputRef}
                    />
                    <input
                      onChange={(e) => onEmailChange(e)}
                      value={email}
                      type="email"
                      className={`banks text-field w-input ${errors[1] && "error"}`}
                      maxLength={256}
                      name="email"
                      data-name="Email"
                      placeholder="Email"
                      id="email"
                      required=""
                    />
                    <input
                      onChange={(e) => onPhoneChange(e)}
                      value={phone}
                      type="tel"
                      className={`banks text-field w-input ${errors[2] && "error"}`}
                      maxLength={256}
                      name="Phone"
                      data-name="Phone"
                      placeholder="Phone"
                      id="Phone"
                    />
                    <textarea
                      onChange={(e) => onMessageChange(e)}
                      value={message}
                      placeholder="Message..."
                      maxLength={5000}
                      id="Message"
                      name="Message"
                      data-name="Message"
                      className={`banks text-field multiline w-input ${errors[3] && "error"}`}
                      defaultValue={""}
                    />
                    <input type="submit" defaultValue="Submit" data-wait="Please wait..." className="banks submit-button w-button" />
                  </form>
                )}
                {mailSent && (
                  <div className="form success">
                    <div>
                      <h2 className="banks bw-blue-text">Thank you!</h2>
                      <p className="banks">Your message has been received. We'll get back to you as soon as possible.</p>
                    </div>
                  </div>
                )}
                {error && (
                  <div className="form fail">
                    <div>
                      <h2 className="banks bw-blue-text">Oops!</h2>
                      <p className="banks">Something went wrong while submitting the form. Please email bellwoodsweb@gmail</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="ad-landing-2">
        <LandingServices />
      </div>

      <div className="ad-landing-3">
        <Link to="/" className="submit-button phone w-button outline">
          View Full Site
        </Link>
        <span
          className="submit-button phone w-button"
          onClick={() => {
            inputRef.current.focus();
          }}
        >
          Get Started!
        </span>
      </div>
    </WebflowPage>
  );
};

export default AdLandingPage;
