import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

import LandingPage from "../pages/LandingPage";
import Portfolio from "../pages/Portfolio";
import ResumePage from "../pages/ResumePage";
import Blog from "../pages/Blog";
import BlogPost from "../pages/BlogPost";
import Login from "../pages/Login";
import Register from "../pages/Register";
import PolicyPage from "../pages/PolicyPage";
import ContactPage from "../pages/ContactPage";
import ErrorPage from "../pages/ErrorPage";
import AdLandingPage from "../pages/AdLandingPage";
import QuickbooksAuthPage from "../components/quickbooks/QuickbooksAuthPage";
import QuickbooksRevokePage from "../components/quickbooks/QuickbooksRevokePage";
import QuickbooksEmbedProfilePage from "../components/quickbooks/QuickbooksEmbedProfilePage";
import QuickbooksEmbedAuthPage from "../components/quickbooks/QuickbooksEmbedAuthPage";

function Navigation(props) {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/promo" element={<AdLandingPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/resume" element={<ResumePage />} />

          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/policy/:id" element={<PolicyPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/quickbooks/embed/auth" element={<QuickbooksEmbedAuthPage />} />
          <Route path="/quickbooks/embed/profile" element={<QuickbooksEmbedProfilePage />} />
          <Route path="/quickbooks/auth" element={<QuickbooksAuthPage />} />
          <Route path="/quickbooks/revoke" element={<QuickbooksRevokePage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default Navigation;
