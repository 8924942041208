import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import Loader from "../components/Loader";
import bellwoods from "../apis/bellwoods";
import formatDate from "../hooks/formatDate";
import Page from "../components/Page";

const BlogPost = () => {
  const [post, setPost] = useState([]);
  const [error, setError] = useState(null);
  const { id } = useParams();
  //get all posts on component load
  useEffect(() => {
    bellwoods
      .get(`/api/posts/${id}`)
      .then((response) => {
        console.log(response.data.data);
        setPost(response.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, [id]);

  if (post.id) {
    return (
      <Page>
        <div className="blog">
          <div className="blog-header">
            <div className="ui container">
              <div className="blog-title banks">{post.attributes.title}</div>
              <div className="blog-date banks">{formatDate(post.attributes.created)}</div>
            </div>
            <Link to={`/blog`} className="slide-btn btn btn-large bw-blue banks">
              back
            </Link>
          </div>
          <div className="ui container mt-4 mb-4">
            <div className="blog-content bodini">
              <ReactMarkdown className="bodini blog-card-content">{post.attributes.content}</ReactMarkdown>
              {/* <h5 className="banks">please like & comment</h5> */}
              {/* <Likes postId={id} /> */}
              {/* <CommentsIcon postId={id} /> */}
            </div>
          </div>
          {/* <Comments postId={id} /> */}
        </div>
      </Page>
    );
  } else if (error) {
    return (
      <Page>
        <h1 className="banks text-center mt-4 mb-4">woops... something went wrong!</h1>
      </Page>
    );
  } else {
    return <Loader />;
  }
};

export default BlogPost;
