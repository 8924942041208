import React from "react";
import Navigation from "./Navigation";
import Footer from "./Footer";
import Head from "../navigation/Head";
// import { useLocation } from "react-router-dom";

const Page = (props) => {
  // const location = useLocation();
  // console.log(location?.pathname);

  return (
    <>
      <Head />
      <Navigation />
      {props.children}
      <Footer />
    </>
  );
};

export default Page;
