import React from "react";
import { useState, useEffect } from "react";

import "../css/Modal.css";

function landingTimeout() {
  return new Promise((resolve) => setTimeout(resolve, 1000));
}

const Modal = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    landingTimeout().then(() => {
      setLoading(false);
    });
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);
  };
  return (
    <div className={`landing-window bw-blue h100 ${!isLoading ? "hide-animate" : ""}`} onClick={handleClick}>
      <img className={`modal-logo shadow h30 ${!isLoading ? "" : ""}`} src="/images/logos/10.png" alt="services logo" />
    </div>
  );
};

export default Modal;
