import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Project from "../components/Project";
import "../css/Portfolio.css";
import Resume from "../components/Resume";
import bellwoods from "../apis/bellwoods";
import Loader from "../components/Loader";
import Page from "../components/Page";

const ResumePage = () => {
  const [filter, setFilter] = useState("All");
  const [filterList, setFilterList] = useState([]);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    // get projects
    bellwoods
      .get("/properties")
      .then((response) => {
        setProjects(response.data.sort((a, b) => b.priority - a.priority));
        setFilteredProjects(response.data.sort((a, b) => b.priority - a.priority));
        // set filters
        let tmp = ["All"];
        for (const el of response.data) {
          !tmp.includes(el.industry) && el.industry && tmp.push(el.industry);
        }
        setFilterList(tmp);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (filter === "All") {
      setFilteredProjects(projects.sort((a, b) => b.priority - a.priority));
    } else {
      setFilteredProjects(projects.filter((el) => el.industry === filter).sort((a, b) => b.priority - a.priority));
    }
  }, [filter]);

  const renderProjects = () => {
    return filteredProjects.map((el, i) => {
      return (
        <Col key={i} className="portfolio-col text-center" xs={{ span: 12, offset: 0 }} md={{ span: 4, offset: 0 }} lg={{ span: 3, offset: i % 3 ? 0 : 1 }}>
          <Project project={el} showText={true} />
        </Col>
      );
    });
  };

  if (filteredProjects.length === 0) {
    return <Loader />;
  } else {
    return (
      <Page>
        <div className="portfolio">
          <Resume />
          <div className="portfolio-top">
            <div className="portfolio-background">
              <div className="ui container portfolio-content">
                <select className="custom-select banks bw-blue portfolio-select" onChange={(e) => setFilter(e.target.value)}>
                  {filterList.map((el, i) => {
                    return (
                      <option key={el} value={el}>
                        {el}
                      </option>
                    );
                  })}
                </select>
                <Row className="portfolio-row">{renderProjects()}</Row>
              </div>
            </div>
          </div>
          {/* <Instagram /> */}
        </div>
      </Page>
    );
  }
};

export default ResumePage;
