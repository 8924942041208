import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CurrentUser from "./CurrentUser";

import "../css/Authenticate.css";

const Authenticate = () => {
  const [jwt, setjwt] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      setjwt(localStorage.getItem("jwt"));
    }
  }, []);

  if (jwt) {
    return (
      <span className="authenticate">
        <span className="auth-btn banks">
          hi <CurrentUser />!
        </span>
        <div
          className="auth-btn btn banks zoom"
          onClick={() => {
            localStorage.clear();
            window.location.reload(true);
          }}
        >
          logout
        </div>
      </span>
    );
  } else {
    return (
      <span className="authenticate">
        <Link to="/login" className="auth-btn btn banks zoom">
          login
        </Link>
        <Link to="/register" className="auth-btn btn banks zoom">
          sign up
        </Link>
      </span>
    );
  }
};

export default Authenticate;
