import { useEffect, useState } from "react";
import * as CmsActions from "../store/actions/cms";
import { useDispatch, useSelector } from "react-redux";

// handles Strapi auth flow: login
export const useCms = (slug, populate) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.cms);

  useEffect(() => {
    fetch(slug);
  }, [slug]);

  const fetch = (slug) => {
    if (slug) dispatch(CmsActions.fetch(slug, populate));
  };

  // return those functions
  return {
    data,
  };
};
