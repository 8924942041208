import React from "react";
import Page from "../components/Page";

const ErrorPage = () => {
  return (
    <Page>
      <div className="page">
        <h1 className="banks bw-blue-text">Oops... This page wasnt found?!</h1>
        <p className="bodini "> Please check that you have the right URL</p>
        <h2 className="banks ">404</h2>
      </div>
    </Page>
  );
};

export default ErrorPage;
