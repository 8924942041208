import axios from "axios";

export const FETCH_DATA = "FETCH_DATA";

export const fetch = (slug, populate) => {
  return async (dispatch) => {
    try {
      // make request
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/${slug}?${populate || "populate=*"}`,
      };
      const response = await axios(config);
      console.log(response);
      // set our flag for the banner
      dispatch({
        type: FETCH_DATA,
        slug: slug,
        data: response.data,
      });
    } catch (error) {
      throw error;
    }
  };
};
