import React, { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/Resume.css";

const education = [
  {
    date: "Graduated April 2019",
    title: "BACHELORS OF MECHATRONICS ENGINEERING & MANAGEMENT + CO-OP",
    location: "MCMASTER UNIVERSITY",
    bio: "The Engineering & Management Program provides a deeper understanding of business, project management, and leadership skills needed to thrive in a corporate or entrepreneurial setting.",
    highlights: [
      "Cumulative GPA 9.7 (80-84%)",
      "C/C++ programing of embedded microcontrollers",
      "Control systems, RTOS, and OS analysis and design",
      "Mechanical and Electrical systems modelling and design",
      "Project management, finance, economics, marketing, and HR experience",
    ],
  },
  {
    date: "January 2020 - PRESENT",
    title: "ONLINE EDUCATION",
    location: "UDEMY",
    bio: "Udemy is an American massive open online course provider aimed at professional adults and students. With a background in software engineering, their courses on web development are extremely easy to pick up and master. These are highly reccomended to anyone looking to pursue web development who already has experience with software.",
    highlights: ["Modern React With Redux [2020 Update] (52.5 hours)", "The Web Developer Bootcamp (46.5 hours)"],
  },
];

const experience = [
  {
    date: "JUNE 2020 – PRESENT",
    title: "FOUNDER & FULL-STACK DEVELOPER",
    location: "BELLWOODS WEB DEVELOPMENT",
    bio: "I’ve started my own entrepreneurial venture designing websites and web applications. Building my skills as a software developer while generating multiple streams of income.",
    highlights: [
      "User experience & interface – MERN stack applications – Full-Stack development",
      "Digital marketing on various social media platforms and search engine optimization",
      "Customer service is at the core of every decision I make",
    ],
  },
  {
    date: "MARCH 2021 – PRESENT",
    title: "WEB DEVELOPMENT MANAGER",
    location: "CIVICONNECT",
    bio: "Managing the web development department of a non-profit organization providing students with real world experience, devleloping websites for small businesses in the Niagara region",
    highlights: [
      "Design, development, and deployment of modern web applications",
      "Navigating group dynamics to ensure cohesiveness and productivity",
      "Leading design sprints and monitoring deliverables and KPI’s",
      "Teaching UI/UX, Design, and development in Webflow, React, HTML, CSS, and JavaScript",
    ],
  },
  {
    date: "JUNE 2020 – FEBRUARY 2021",
    title: "CONTROLS SOFTWARE & ELECTRONICS ENGINEER",
    location: "MULTIMATIC TECHNICAL CENTRE ",
    bio: "Developing prototype and production CSE systems for a wide range of automotive OEM’s.",
    highlights: [
      "Programming ECU’s to control motorized mechanisms through sensor feedback loops",
      "Coding Matlab applications to demonstrate new products",
      "PCB schematic and layout design and manufacturing",
      "Wiring and soldering of circuit boards, wiring harnesses, DIN rail control panels",
    ],
  },
  {
    date: "JUNE 2019 – JUNE 2020",
    title: "MECHATRONICS DESIGN ENGINEER",
    location: "MULTIMATIC TECHNICAL CENTRE",
    bio: "Designing electromechanical automotive systems such as power doors and active aerodynamics. Involved in every stage of the product lifecycle from concept to post production support with the ability to make significant contributions.",
    highlights: [
      "Hands on experience prototyping and testing",
      "Communicating with a wide range of customers, suppliers, and internal stakeholders",
      "Design for production manufacturing and assembly",
      "Developed VBA software tools to assist with design and project management",
    ],
  },
  {
    date: "JANUARY 2018 – MAY 2019",
    title: "RESEARCH ASSISTANT",
    location: "MCMASTER AUTOMOTIVE RESEARCH CENTRE",
    bio: "A partnership between McMaster University and Fiat Chrysler Automotive to develop the next generation of electronic vehicle architecture.",
    highlights: [
      "Created and modified MATLAB Simulink scripts and models for code generation",
      "Transitioned legacy code and E/E architectures to be AUTOSAR compliant",
      "Worked with ECU’s communicating over CAN/LIN/Ethernet networks",
    ],
  },
  {
    date: "SEPTEMBER 2015 – MAY 2019",
    title: "TEAM LEAD",
    location: "MCMASTER FORMULA SAE",
    bio: "Formula SAE is a global competition where students are responsible for the design, simulation, and manufacturing of a quarter scale Formula 1 style race-car. Our team worked to develop a world-class battery electric vehicle to compete throughout North America and Europe.",
    highlights: [
      "Coordinated with industry leaders to secure corporate sponsorships",
      "Managed a team of over 100 students with a $100,000 budget",
      "Designed the vehicle's suspension, steering, and brakes systems, as well as an electro-mechanically actuated rear wheel steering system",
      "Developed vehicle control systems and wrote code to filter and analyse test data",
      "Applied theoretical knowledge learned in class to a practical application",
    ],
  },
  {
    date: "SEPTEMBER 2016 – APRIL 2017",
    title: "INSTRUCTIONAL ASSISTANT INTERN",
    location: "MCMASTER UNIVERSITY ENGINEERING",
    bio: "Taught labs and tutorials for the first year engineering design and 3D modelling course.",
    highlights: [
      "Demonstrated organizational and communication skills while working with over 1000 students and managing a team of 25 teaching assistants",
      "Improved my ability to clearly communicate complex concepts in a way that promotes knowledge retention",
    ],
  },
];

const skills = [
  {
    title: "PROGRAMMING",
    contents: ["C/C++", "Python", "JavaScript", "Excel VBA", "Matlab / Simulink", "Bash Script", "Command Line"],
  },
  {
    title: "COMPUTER AIDED DESIGN ",
    contents: ["NX", "Catia", "SolidWorks", "Autodesk", "Eagle"],
  },
  {
    title: "WEB DEVELOPMENT",
    contents: ["MongoDB - Express - React & React Native - Node", "HTML – CSS – JavaScript", "Shopify - Bookmark - Wix - Wordpress", "Bootstrap – Semantic UI - Material UI"],
  },
  {
    title: "MANUFACTURING",
    contents: ["3D Printing", "Manual Mill / Lathe", "CNC Machining", "Waterjet / Laser Cut ", "Electrical wiring", "Soldering / PCB", "Woodworking"],
  },
];

const renderTooltip = (copied) => (
  <Tooltip id="button-tooltip">
    <i className={`icon ${copied ? "check" : "copy outline"}`} />
  </Tooltip>
);

const Resume = () => {
  const [copied, setCopied] = useState(false);
  const [selected, setSelected] = useState("education");
  return (
    <div className="resume">
      <img className="zoom shadow headshot" src="../images/liam.png" alt="headshot" />
      <div className="resume-name banks">Liam Burns</div>
      <Row className="resume-socials bodini text-center">
        <Col xs={{ span: 12, offset: 0 }} md={{ span: 2, offset: 3 }}>
          Toronto Ontario
        </Col>
        <Col xs={{ span: 12, offset: 0 }} md={{ span: 2, offset: 0 }}>
          <a href="tel:289-253-7312">(289) 253-7312</a>
        </Col>
        <Col
          xs={{ span: 12, offset: 0 }}
          md={{ span: 2, offset: 0 }}
          onClick={() => {
            navigator.clipboard.writeText("bellwoodsweb@gmail.com");
            setCopied(true);
          }}
        >
          <OverlayTrigger placement="right" delay={{ show: 200, hide: 400 }} overlay={renderTooltip(copied)}>
            <span className={copied ? "text-muted" : "hover"}>{copied ? "copied to clipboard" : "bellwoodsweb@gmail.com"}</span>
          </OverlayTrigger>
        </Col>
      </Row>
      <div className="ui container">
        <p className="resume-intro banks">
          With the right mix of attitude experience and intuition, no problem is insurmountable. Broad range of technical skills from web development to racecar design with the interpersonal skills to
          thrive in a team environment. Constantly pushing myself to grow as an individual through new experiences, travel, learning, and communication.
        </p>
        <hr />
      </div>

      <div className="ui container">
        <Row className="resume-toggler text-center">
          <Col xs={{ span: 4, offset: 0 }}>
            <div className={`banks resume-button zoom shadow ${selected === "education" ? "grad" : "bw-blue"}`} onClick={() => setSelected("education")}>
              <i className="fas fa-graduation-cap"></i>
            </div>
          </Col>
          <Col xs={{ span: 4, offset: 0 }}>
            <div className={`banks resume-button zoom shadow ${selected === "experience" ? "grad" : "bw-blue"}`} onClick={() => setSelected("experience")}>
              <i className="fas fa-briefcase"></i>
            </div>
          </Col>
          <Col xs={{ span: 4, offset: 0 }}>
            <div className={`banks resume-button zoom shadow ${selected === "skills" ? "grad" : "bw-blue"}`} onClick={() => setSelected("skills")}>
              <i className="fas fa-hammer"></i>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 12, offset: 0 }}
            sm={{
              span: 4,
              offset: selected === "skills" ? 8 : selected === "experience" ? 4 : 0,
            }}
          >
            <div className={`resume-header banks ${selected === "education" ? "" : "d-none"}`}>education</div>
            <div className={`resume-header banks ${selected === "experience" ? "" : "d-none"}`}>experience</div>
            <div className={`resume-header banks ${selected === "skills" ? "" : "d-none"}`}>skills</div>
          </Col>
        </Row>
      </div>

      <div className="ui container">
        <div className={`${selected === "education" ? "resume-visible" : "resume-invisible"}`}>
          <ResumeSection info={education} />
        </div>
        <div className={`${selected === "experience" ? "resume-visible" : "resume-invisible"}`}>
          <ResumeSection info={experience} />
        </div>

        <div className={`${selected === "skills" ? "resume-visible" : "resume-invisible"}`}>
          <Row>
            <ResumeSkills info={skills} />
          </Row>
        </div>
      </div>
    </div>
  );
};

const ResumeSection = ({ info }) => {
  return info.map((item, i) => {
    return (
      <div key={i} className="resume-item">
        <p className="resume-date banks">{item.date}</p>
        <p className="resume-title banks">{item.title}</p>
        <p className="resume-location banks">{item.location}</p>
        <p className="resume-bio bodini">{item.bio}</p>
        <ul className="resume-highlights bodini">{renderHighlights(item.highlights)}</ul>
      </div>
    );
  });
};

const ResumeSkills = ({ info }) => {
  return info.map((item, i) => {
    return (
      <Col key={i} xs={{ span: 12, offset: 0 }} sm={{ span: 10, offset: 1 }} md={{ span: 4, offset: i % 2 ? 0 : 2 }}>
        <div className="resume-skill-section">
          <p className="resume-skill-title banks">{item.title}</p>
          <ul className="resume-highlights bodini">{renderHighlights(item.contents)}</ul>
        </div>
      </Col>
    );
  });
};

const renderHighlights = (highlights) => {
  return highlights.map((item, i) => {
    return (
      <li key={i} className="resume-highlight">
        {item}
      </li>
    );
  });
};
export default Resume;
