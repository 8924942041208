import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import bellwoods from "../apis/bellwoods";
import history from "../history";
const FormData = require("form-data");

const Register = ({ redirect }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    localStorage.clear();

    const formData = new FormData();
    formData.append("username", firstName + lastName);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);

    bellwoods
      .post("/api/auth/local/register", formData)
      .then((response) => {
        localStorage.setItem("jwt", response.data.jwt);
        localStorage.setItem("firstName", response.data.user.firstName);
        localStorage.setItem("lastName", response.data.user.lastName);
        localStorage.setItem("userId", response.data.user.id);
        setError(false);

        history.push(redirect || "/blog");
        window.location.reload(true);
      })
      .catch((error) => {
        setError(true);
      });
  };
  return (
    <div className="banks auth-form">
      <div className="centered auth-form-inner">
        <form className="ui form">
          <h1 className="banks">Sign Up</h1>
          <h5 className={`banks ${!error ? "d-none" : ""}`}>woops! something went wrong... please try again</h5>
          <h5 className={`banks ${error ? "d-none" : ""}`}>were excited to hear from you!</h5>
          <div className="field">
            <input type="text" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} className="banks" />
          </div>
          <div className="field">
            <input type="text" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} className="banks" />
          </div>
          <div className="field">
            <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} className="banks" />
          </div>
          <div className="field">
            <input type="text" placeholder="Password" onChange={(e) => setPassword(e.target.value)} className="banks" />
          </div>
          <Row>
            <Col xs={{ span: 12, offset: 0 }} sm={{ span: 6, offset: 0 }} className="mt-2 mb-2">
              <button className="ui button banks secondary w-100" type="submit" onClick={onSubmit}>
                register
              </button>
            </Col>
            <Col xs={{ span: 12, offset: 0 }} sm={{ span: 6, offset: 0 }} className="mt-2 mb-2">
              <Link className="ui button bw-blue banks w-100" to="/login">
                already a member?
              </Link>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
};

export default Register;

//     <form className="ui form">
//
//       <div className="field">
//         <input
//           type="email"
//           placeholder="Email"
//           onChange={(e) => setEmail(e.target.value)}
//           className="banks"
//         />
//       </div>
//       <div className="field">
//         <input
//           type="text"
//           placeholder="Password"
//           onChange={(e) => setPassword(e.target.value)}
//           className="banks"
//         />
//       </div>
//     </form>
//   </div>
// </div>;
