import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PAGENUM = 6;
const Page6 = ({ errors, onValuesChange, state }) => {
  const { page, name, phone, email, industry, company, goal } = state;
  return (
    <div
      className="quote-page"
      style={
        page === PAGENUM
          ? { maxHeight: "1000vh" }
          : { maxHeight: "0", height: "0" }
      }
    >
      <Row>
        <Col xs={{ span: 12, offset: 0 }} md={{ span: 6, offset: 3 }}>
          <div className={`field ${errors[PAGENUM][0] ? "error" : ""}`}>
            <input
              type="text"
              name="name"
              placeholder="your name"
              onChange={(e) => onValuesChange(e, "name", PAGENUM, 0)}
              value={name}
              className="bodini"
            />
          </div>
          <div className={`field ${errors[PAGENUM][1] ? "error" : ""}`}>
            <input
              type="text"
              name="phone"
              placeholder="phone number"
              onChange={(e) => onValuesChange(e, "phone", PAGENUM, 1)}
              value={phone}
              className="bodini"
            />
          </div>
          <div className={`field ${errors[PAGENUM][2] ? "error" : ""}`}>
            <input
              type="text"
              name="email"
              placeholder="email address"
              onChange={(e) => onValuesChange(e, "email", PAGENUM, 2)}
              value={email}
              className="bodini"
            />
          </div>
          <div className={`field ${errors[PAGENUM][3] ? "error" : ""}`}>
            <input
              type="text"
              name="company"
              placeholder="company name"
              onChange={(e) => onValuesChange(e, "company", PAGENUM, 3)}
              value={company}
              className="bodini"
            />
          </div>
          <div className={`field ${errors[PAGENUM][4] ? "error" : ""}`}>
            <input
              type="text"
              name="industry"
              placeholder="company industry"
              onChange={(e) => onValuesChange(e, "industry", PAGENUM, 4)}
              value={industry}
              className="bodini"
            />
          </div>
          <div className={`field ${errors[PAGENUM][5] ? "error" : ""}`}>
            <select
              name="goal"
              onChange={(e) => onValuesChange(e, "goal", PAGENUM, 5)}
              value={goal}
              className="ui fluid dropdown bodini large"
            >
              <option className="" value="">
                main goal for your new site?
              </option>
              <option value="update it on my own">update it on my own</option>
              <option value="up and running yesterday">
                up and running yesterday
              </option>
              <option value="looks awesome">looks awesome</option>
              <option value="lots of functionality">
                lots of functionality
              </option>
              <option value="spend as little money as possible">
                spend as little money as possible
              </option>
            </select>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Page6;
