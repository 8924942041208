// Weblfow Accordions tend not to work well once exported
// This allows us to have a Strapi CMS linked Accordion
// May need some modificatons to match your webflow code structure

import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import "../css/Accordion.css";
import { Link } from "react-router-dom";
import RichText from "./RichText";

const Accordion = ({ data }) => {
  if (!data) return null;
  return (
    <div className="Accordion">
      {data.map((el) => {
        return <AccordionItem key={uuid()} heading={el.heading} body={el.body} button={el.button} />;
      })}
    </div>
  );
};

const AccordionItem = ({ heading, body, button }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={`AccordionItem  ${open ? "active shadow" : null}`}>
      <div className={`AccordionHeader banks ${!open && "shadow"}`} onClick={() => setOpen(!open)}>
        {heading}
      </div>
      <div className="AccordionBody ">
        <RichText text={body} left className="bodini" />
        {button && (
          <Link target={"_blank"} rel="noopener noreferrer" to={button.slug || "/"} className="ui button seemore shadow banks large">
            {button.text}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Accordion;
