import React, { useState } from "react";
import bellwoods from "../../apis/bellwoods";
import "../../css/Quickbooks.css";
import Page from "../Page";

const QuickbooksAuthPage = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    bellwoods
      .get(`/api/quickbooks/auth?email=${email}`)
      .then((response) => {
        window.location.href = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Page>
      <div className="page">
        <p>Register for</p>
        <h2>Bellwoods - Quickbooks Integration</h2>

        <form className="ui large form" onSubmit={handleSubmit}>
          <div className={`field mt-4`}>
            <input required type="email" id="email" name="email" placeholder="what's your email?" onChange={(e) => setEmail(e.target.value)} value={email} className="bodini " />
          </div>
          <button type="submit" className="w-100 ui button bw-blue banks large send-button shadow">
            Go!
          </button>
        </form>
      </div>
    </Page>
  );
};

export default QuickbooksAuthPage;
