import React from "react";
import axios from "axios";

import "../css/ContactQuote.css";
import Page1 from "./quote/Page1";
import Page2 from "./quote/Page2";
import Page3 from "./quote/Page3";
import Page4 from "./quote/Page4";
import Page5 from "./quote/Page5";
import Page6 from "./quote/Page6";

const API_PATH = "https://www.bellwoodsweb.com/php/quoteForm.php";

class ContactQuote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advertising: "",
      application: "",
      blog: "",
      branding: "",
      careers: "",
      company: "",
      contact: "",
      content: "",
      description: "",
      donations: "",
      ecommerce: "",
      email: "",
      events: "",
      faq: "",
      gallery: "",
      giftcards: "",
      goal: "",
      industry: "",
      invoices: "",
      login: "",
      maps: "",
      name: "",
      news: "",
      phone: "",
      photography: "",
      portfolio: "",
      process: "",
      products: "",
      projects: "",
      resources: "",
      signup: "",
      slideshow: "",
      socials: "",
      specials: "",
      sponsors: "",
      staff: "",
      testimonials: "",
      tickets: "",
      writing: "",

      errors: [
        [false, false, false, false, false, false],
        [false, false, false, false, false, false],
        [false, false, false, false, false, false],
        [false, false, false, false, false, false],
        [false, false, false, false, false, false],
        [false, false, false, false, false, false],
        [false, false, false, false, false, false],
      ],

      page: 1,
      maxPage: 6,
      mailSent: false,
      error: null,
    };
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      axios({
        method: "post",
        url: `${API_PATH}`,
        headers: { "content-type": "application/json" },
        data: this.state,
      })
        .then((result) => {
          this.setState({
            mailSent: result.data.sent,
          });
          this.clearForm();
        })
        .catch((error) => {
          this.setState({ error: error.message });
          this.clearForm();
        });
    }
  };

  clearForm = () => {
    this.setState({
      advertising: "",
      application: "",
      blog: "",
      branding: "",
      careers: "",
      company: "",
      contact: "",
      content: "",
      description: "",
      donations: "",
      ecommerce: "",
      email: "",
      events: "",
      faq: "",
      gallery: "",
      giftcards: "",
      goal: "",
      industry: "",
      invoices: "",
      login: "",
      maps: "",
      name: "",
      news: "",
      phone: "",
      photography: "",
      portfolio: "",
      process: "",
      products: "",
      projects: "",
      resources: "",
      signup: "",
      slideshow: "",
      socials: "",
      specials: "",
      sponsors: "",
      staff: "",
      testimonials: "",
      tickets: "",
      writing: "",
      errors: [
        [false, false, false, false, false, false],
        [false, false, false, false, false, false],
        [false, false, false, false, false, false],
        [false, false, false, false, false, false],
        [false, false, false, false, false, false],
        [false, false, false, false, false, false],
        [false, false, false, false, false, false],
      ],
      page: 1,
    });
  };

  validate = () => {
    const { name, email } = this.state;
    const tmpErr = [false, false, false, false, false, false];
    let result = true;

    if (!name) tmpErr[0] = true;
    if (!this.validateEmail(email)) tmpErr[2] = true;

    //set errors state so cells go red
    const tmpErr2 = this.state.errors.map((el, i) => (i === 6 ? (el = tmpErr) : el));
    this.setState({ errors: tmpErr2 });

    //decide if form should be submitted
    tmpErr.map((el) => {
      if (el) {
        result = false;
        return true;
      }
      return false;
    });
    return result;
  };

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onValuesChange = (e, type, page, index) => {
    var obj = {};
    obj[type] = e.target.value;
    this.setState(obj);
    this.setError(e.target.value, index, page);
  };

  setPage = (e, index) => {
    e.preventDefault();
    this.setState({ page: index });
  };

  setError = (value, index, page) => {
    const tmp = this.state.errors[page].map((el, i) => (i === index ? !value : el));

    const tmp2 = this.state.errors.map((el, i) => (i === page ? tmp : el));
    this.setState({ errors: tmp2 });
  };

  render() {
    const { page, maxPage } = this.state;
    return (
      <div className="ui container ">
        <div className="quote-toggler pointer">
          <QuoteToggler setPage={this.setPage} page={page} maxPage={maxPage} />
        </div>
        <form action="#" className={`noshaddow  ui large form quote-form ${this.state.mailSent || this.state.error ? "contact-hidden" : ""}`}>
          <Page1 errors={this.state.errors} onValuesChange={this.onValuesChange} state={this.state} />
          <Page2 errors={this.state.errors} onValuesChange={this.onValuesChange} state={this.state} />
          <Page3 errors={this.state.errors} onValuesChange={this.onValuesChange} state={this.state} />
          <Page4 errors={this.state.errors} onValuesChange={this.onValuesChange} state={this.state} />
          <Page5 errors={this.state.errors} onValuesChange={this.onValuesChange} state={this.state} />
          <Page6 errors={this.state.errors} onValuesChange={this.onValuesChange} state={this.state} />

          <div className="field text-center mt-4 quote-buttons">
            <button className={`ui button bw-blue shadow banks large ${page === 1 ? "d-none" : ""}`} onClick={(e) => this.setPage(e, page - 1)}>
              back
            </button>
            <button className={`ui button bw-blue shadow banks large ${page === maxPage ? "d-none" : ""}`} onClick={(e) => this.setPage(e, page + 1)}>
              next
            </button>

            <button type="submit" name="submit" className={`ui button bw-darkblue shadow banks large ${page === maxPage ? "" : "d-none"}`} onClick={(e) => this.handleFormSubmit(e)}>
              submit
            </button>
          </div>
        </form>
        <div className="text-center">
          {this.state.mailSent && (
            <>
              <h2 className="banks">thank you!</h2>
              <h4 className="banks">we'll get back to you as soon as possible</h4>
            </>
          )}
          {this.state.error && (
            <>
              <h2 className="banks">whoops...</h2>
              <h4 className="banks">something went wrong, please email bellwoodsweb@gmail.com</h4>
            </>
          )}
        </div>
      </div>
    );
  }
}

const QuoteToggler = ({ page, setPage, maxPage }) => {
  const toggler = [];
  var i;
  for (i = 0; i < maxPage; i++) {
    toggler.push(null);
  }
  return toggler.map((el, i) => {
    return <div key={i} className={`quote-toggler-button hover ${page === i + 1 ? "quote-active" : ""}`} onClick={(e) => setPage(e, i + 1)}></div>;
  });
};

export default ContactQuote;
