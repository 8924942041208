import { FETCH_DATA } from "../actions/cms";

const initialState = {
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.slug]: action.data,
        },
      };

    default:
      return state;
  }
};
