import React from "react";

import Header from "../components/Header";
import Reviews from "../components/Reviews";
import Popup from "../components/Popup";
import Page from "../components/Page";
import OurWork from "../components/OurWork";
import LandingServices from "../components/LandingServices";
import Clients from "../components/Clients";

const LandingPage = () => {
  return (
    <Page>
      {/* <div> */}
      <Header />
      <Reviews />
      <Clients />
      <LandingServices />
      {/* <About /> */}
      <OurWork />
      <Popup delay={6000} deactivate />

      {/* <Contact defaultState="contact" noHeightAdded /> */}
      {/* <FAQs /> */}
      {/* </div> */}
    </Page>
  );
};

export default LandingPage;
