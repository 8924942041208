import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PAGENUM = 2;
const Page2 = ({ errors, onValuesChange, state }) => {
  const { page } = state;
  return (
    <div
      className="quote-page"
      style={
        page === PAGENUM
          ? { maxHeight: "1000vh" }
          : { maxHeight: "0", height: "0" }
      }
    >
      <Row>
        <Col xs={{ span: 12, offset: 0 }} md={{ span: 6, offset: 0 }}>
          {/* photography */}
          <div className="grouped fields">
            <label className="banks" htmlFor="photography">
              will you need professional photography:
            </label>
            <div className="field">
              <div className="ui radio bodini">
                <label>
                  <input
                    type="radio"
                    name="photography"
                    value="yes"
                    onChange={(e) =>
                      onValuesChange(e, "photography", PAGENUM, 0)
                    }
                  />
                  <span className="ml-3">yes</span>
                </label>
              </div>
            </div>

            <div className="field">
              <div className="ui radio bodini">
                <label>
                  <input
                    type="radio"
                    name="photography"
                    value="no"
                    onChange={(e) =>
                      onValuesChange(e, "photography", PAGENUM, 0)
                    }
                  />
                  <span className="ml-3">no</span>
                </label>
              </div>
            </div>
          </div>

          {/* branding */}
          <div className="grouped fields">
            <label className="banks" htmlFor="branding">
              how about professional branding:
            </label>
            <div className="field">
              <div className="ui radio bodini">
                <label>
                  <input
                    type="radio"
                    name="branding"
                    value="yes"
                    onChange={(e) => onValuesChange(e, "branding", PAGENUM, 1)}
                  />
                  <span className="ml-3">
                    i need logos, fonts, and a color scheme
                  </span>
                </label>
              </div>
            </div>

            <div className="field">
              <div className="ui radio bodini">
                <label>
                  <input
                    type="radio"
                    name="branding"
                    value="no"
                    onChange={(e) => onValuesChange(e, "branding", PAGENUM, 1)}
                  />
                  <span className="ml-3">don't need it</span>
                </label>
              </div>
            </div>
            <div className="field">
              <div className="ui radio bodini">
                <label>
                  <input
                    type="radio"
                    name="branding"
                    value="have"
                    onChange={(e) => onValuesChange(e, "branding", PAGENUM, 1)}
                  />
                  <span className="ml-3">already have it</span>
                </label>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          {/* writing */}
          <div className="grouped fields">
            <label className="banks" htmlFor="writing">
              who will write the text for your website:
            </label>
            <div className="field">
              <div className="ui radio bodini">
                <label>
                  <input
                    type="radio"
                    name="writing"
                    value="client"
                    onChange={(e) => onValuesChange(e, "writing", PAGENUM, 2)}
                  />
                  <span className="ml-3">i will write it</span>
                </label>
              </div>
            </div>

            <div className="field">
              <div className="ui radio bodini">
                <label>
                  <input
                    type="radio"
                    name="writing"
                    value="developer"
                    onChange={(e) => onValuesChange(e, "writing", PAGENUM, 2)}
                  />
                  <span className="ml-3">
                    i would like a professional to write the content for me
                  </span>
                </label>
              </div>
            </div>
          </div>

          {/* content */}
          <div className="grouped fields">
            <label className="banks" htmlFor="content">
              who will be able to edit the content & pages of your site:
            </label>
            <div className="field">
              <div className="ui radio bodini">
                <label>
                  <input
                    type="radio"
                    name="content"
                    value="client"
                    onChange={(e) => onValuesChange(e, "content", PAGENUM, 3)}
                  />
                  <span className="ml-3">i would like to be able to edit</span>
                </label>
              </div>
            </div>

            <div className="field">
              <div className="ui radio bodini">
                <label>
                  <input
                    type="radio"
                    name="content"
                    value="developer"
                    onChange={(e) => onValuesChange(e, "content", PAGENUM, 3)}
                  />
                  <span className="ml-3">
                    i would like a professional to handle it all
                  </span>
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Page2;
