import React from "react";
import "../css/Header.css";
import { PopupButton } from "react-calendly";

const cardContent = [
  {
    banner: "Phase 1",
    icon: "./images/consult.png",
    title: "Consult",
    body: "We'll find areas for cost savings or operational improvements, and provide you with a proposal.",
  },
  {
    banner: "Phase 2",
    icon: "./images/implement.png",
    title: "Implement",
    body: "We'll build out a mixture of custom software and 3rd party integrations that solve your problems.",
  },
  {
    banner: "Phase 3",
    icon: "./images/support.png",
    title: "Support",
    body: "We'll help with technology adoption through change management in your organization. We can also provide ongoing maintenance when applicable.",
  },
];

const Header = () => {
  return (
    <div className="header ">
      <div className="header-overlay">
        <div className="header-container">
          <img className="header-logo-full " src="/images/logos/BellwoodsWeb_White.png" alt="services logo" />

          <div className="header-row">
            {cardContent.map((card) => (
              <div className="header-card shadow">
                {card.banner && (
                  <div class="ribbon ribbon-top-left">
                    <span>{card.banner}</span>
                  </div>
                )}
                <img className="header-icon shadow" src={card.icon} />
                <h2 className="banks bw-blue-text">{card.title}</h2>
                <p>{card.body}</p>
              </div>
            ))}
          </div>

          {/* <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet">
            <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script>
            <a href="" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/bellwoodsweb/30min'});return false;">
              Schedule time with me
            </a>
          </link> */}
          <div className="calendly banks">
            <PopupButton
              url="https://calendly.com/bellwoodsweb/30min"
              /*
               * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
               * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
               */
              rootElement={document.getElementById("root")}
              text="Book a Free Discovery Call →"
            />
          </div>
          {/* <Subscribe /> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
