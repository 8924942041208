const formatDate = (date) => {
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const sp = date.substring(0, 10).split("-");
  return `${months[sp[1] - 1]} ${sp[2]}, ${sp[0]}`;
};

export default formatDate;
