import React from "react";
import { useCms } from "../hooks/useCms";
import Accordion from "./Accordion";
import qs from "qs";
import { Link } from "react-router-dom";

const FAQs = ({ limit }) => {
  const { data } = useCms(
    "faq",
    qs.stringify({
      populate: {
        faqs: {
          populate: {
            button: {
              populate: "*",
            },
          },
        },
      },
    })
  );

  return (
    <div className="faqs">
      <div className="container">
        <h2 className="banks bw-blue-text faqheader">Frequently Asked Questions</h2>
        <Accordion data={limit ? data?.faq?.data?.attributes?.faqs?.slice(0, limit) : data?.faq?.data?.attributes?.faqs} />
        {limit && data?.faq?.data?.attributes?.faqs.slice(0, limit).length < data?.faq?.data?.attributes?.faqs.length && (
          <div className="buttoncentered">
            <Link to="/contact" className="ui button seemore shadow banks large bw-lightblue">
              See More...
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default FAQs;
