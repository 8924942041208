import React, { useState } from "react";
import "../css/LandingServices.css";
import { useCms } from "../hooks/useCms";

const LandingServices = () => {
  const { data } = useCms("services");
  const [active, setActive] = useState(null);

  if (!data?.services?.data || data?.services?.data?.length === 0) return null;

  let sorted = [...data?.services?.data]?.sort((a, b) => {
    return a?.attributes?.order - b?.attributes?.order;
  });

  let tmp = [];
  const chunkSize = 3;
  for (let i = 0; i < sorted.length; i += chunkSize) {
    const chunk = sorted.slice(i, i + chunkSize);
    // do whatever
    tmp.push(chunk);
  }

  return (
    <div className="landing-services-section">
      <div className="landing-services">
        <h2 className="banks bw-blue-text">It's what we do.</h2>
        <div className="landing-services-inner ">
          {tmp?.map((chunk, i) => {
            return (
              <div key={i} className="landing-services-inner-chunk">
                {chunk?.map((service, j) => {
                  let index = i * chunkSize + j;
                  return (
                    <div
                      key={service.id}
                      className={`service ${active === index ? "service-active" : "service-deactive"} `}
                      onClick={() => {
                        if (active === null || active !== index) {
                          setActive(index);
                        } else if (active === index) {
                          setActive(null);
                        }
                      }}
                    >
                      <img src={service?.attributes?.icon?.data?.attributes?.url} alt={service.attributes.title + " Icon"} />
                      <h3 className="banks bw-blue-text">{service?.attributes?.title}</h3>
                      <p className="bodini p">{service?.attributes?.description}</p>
                      <p className={`bodini ${active !== index ? "hiddenTextH0" : "showTextH0"}`}>{service?.attributes?.body}</p>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LandingServices;
