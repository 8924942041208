import React from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Socials from "./Socials";
import "../css/Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-overlay">
        <Row>
          <Col xs={{ span: 12, offset: 0 }} md={{ span: 6, offset: 0 }}>
            <Link to="/">
              <img className="footer-centered footer-logo zoom" src="/images/logos/BellwoodsWeb_White.png" alt="services logo" />
            </Link>
          </Col>
          <Col xs={{ span: 12, offset: 0 }} md={{ span: 6, offset: 0 }}>
            <Socials ig fb li em ph />
          </Col>
        </Row>
        <button
          type="button"
          id="hs_show_banner_button"
          style={{
            backgroundColor: "transparent",
            textDecoration: "none",
            color: "#fff",
            fontFamily: "inherit",
            fontSize: "inherit",
            fontWeight: "normal",
            lineHeight: "inherit",
            textAlign: "center",
            textShadow: "none",
            width: "100%",
          }}
          onClick={() => {
            var _hsp = (window._hsp = window._hsp || []);
            _hsp.push(["showBanner"]);
          }}
        >
          Cookie Settings
        </button>
      </div>
    </div>
  );
};

export default Footer;
