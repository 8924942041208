import React from "react";
import { useState } from "react";
import ContactEmail from "./ContactEmail";
import "../css/Contact.css";
import ContactQuote from "./ContactQuote";

const Contact = ({ defaultState, className, noHeightAdded }) => {
  const [selected, setSelected] = useState(defaultState || "contact");

  return (
    <div className={`${className} contact  ${noHeightAdded ? "" : "mh90"}`}>
      {/* <div className="contact-toggler text-center">
        <div className={`ui button large banks ${selected === "contact" ? "left-grad-selected" : "right-grad-unselected"}`} onClick={() => setSelected("contact")}>
          <div className="zoom">contact</div>
        </div>
        <div className={`ui button large banks ${selected === "quote" ? "right-grad-selected" : "left-grad-unselected"}`} onClick={() => setSelected("quote")}>
          <div className="zoom">quote</div>
        </div>
      </div> */}
      {renderForms(selected)}
    </div>
  );
};

const renderForms = (selected) => {
  return (
    <div className="contact-forms">
      <div className={`contact-form ${selected === "contact" ? "contact-selected" : "contact-hidden"}`}>
        <ContactEmail />
      </div>
      <div className={`contact-form ${selected === "quote" ? "contact-selected" : "contact-hidden"}`}>
        <ContactQuote />
      </div>
    </div>
  );
};

export default Contact;
