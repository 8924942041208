import Col from "react-bootstrap/Col";
import React from "react";
import RichText from "./RichText";

const Partner = ({ data, i, size }) => {
  return (
    <Col xs={{ span: 12, offset: 0 }} lg={size || { span: 8, offset: 2 }}>
      <div className={`partnership shadow ${i % 2 && "odd"}`}>
        <div className="partnershipimg" data-img={`url(${data?.attributes?.cover?.data?.attributes?.url})`} style={{ "--bg-image": `url(${data?.attributes?.cover?.data?.attributes?.url})` }}></div>
        <div className="content">
          <h3 className="banks bw-blue-text" style={{ fontSize: 32 }}>
            {data?.attributes?.title}
          </h3>
          <RichText text={data?.attributes?.body} left className="partnershiprtf" />
          <div className="btns">
            {data?.attributes?.websiteLink && (
              <a target="_blank" className="banks sitebtn" href={data?.attributes?.websiteLink}>
                Visit Site
              </a>
            )}
            {data?.attributes?.appStoreLink && (
              <a target="_blank" className="banks" href={data?.attributes?.appStoreLink}>
                <img className="appleimg" src="images/appledownload.png" />
              </a>
            )}
          </div>
        </div>
      </div>
      <svg style={{ visibility: "hidden", position: "absolute" }} width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="round">
            <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
          </filter>
        </defs>
      </svg>
    </Col>
  );
};

export default Partner;
