import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PAGENUM = 4;
const Page4 = ({ errors, onValuesChange, state }) => {
  const { page } = state;
  return (
    <div
      className="quote-page"
      style={
        page === PAGENUM
          ? { maxHeight: "1000vh" }
          : { maxHeight: "0", height: "0" }
      }
    >
      <div className="field">
        <label className="banks text-center">
          what additional functionality does your site need:
        </label>
      </div>
      <Row>
        <Col xs={{ span: 9, offset: 3 }} md={{ span: 5, offset: 2 }}>
          {/* categories */}
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="gallery"
                value="yes"
                onChange={(e) => onValuesChange(e, "gallery", PAGENUM, 0)}
              />
              <label>photo gallery</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="slideshow"
                value="yes"
                onChange={(e) => onValuesChange(e, "slideshow", PAGENUM, 0)}
              />
              <label>rotating slideshow of images</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="application"
                value="yes"
                onChange={(e) => onValuesChange(e, "application", PAGENUM, 0)}
              />
              <label>online application for employment</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="advertising"
                value="yes"
                onChange={(e) => onValuesChange(e, "advertising", PAGENUM, 0)}
              />
              <label>advertising portal</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="maps"
                value="yes"
                onChange={(e) => onValuesChange(e, "maps", PAGENUM, 0)}
              />
              <label>maps with multiple locations</label>
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 9, offset: 3 }}
          md={{ span: 5, offset: 0 }}
          className="mt-4 mt-md-0"
        >
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="login"
                value="yes"
                onChange={(e) => onValuesChange(e, "login", PAGENUM, 0)}
              />
              <label>user login / password protected areas</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="socials"
                value="yes"
                onChange={(e) => onValuesChange(e, "socials", PAGENUM, 0)}
              />
              <label>social share buttons</label>
            </div>
          </div>
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="signup"
                value="yes"
                onChange={(e) => onValuesChange(e, "signup", PAGENUM, 0)}
              />
              <label>e-newsletter sign up</label>
            </div>
          </div>{" "}
          <div className="field bodini">
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="contact"
                value="yes"
                onChange={(e) => onValuesChange(e, "contact", PAGENUM, 0)}
              />
              <label>email contact form</label>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Page4;
