import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as AuthActions from "../store/actions/auth";
import { pullFormData } from "../util/pullFormData";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

// handles Strapi auth flow: login
export const useAuthFunctions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, jwt } = useSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);

  const findMe = () => {
    if (jwt) dispatch(AuthActions.findMe(jwt));
  };

  // create a function to be called by our form
  const handleSignup = (e) => {
    e.preventDefault();
    let data = pullFormData(e);
    dispatch(AuthActions.register(data));
  };

  // create a function to be called by our form
  const handleLogin = (e) => {
    if (e) {
      e.preventDefault();
      let data = pullFormData(e);
      data.identifier = data.email;
      dispatch(AuthActions.login(data));
    }
  };

  // create a function to be called by our form
  const handleLogout = () => {
    dispatch(AuthActions.logout());
    navigate("/quickbooks/embed/auth");
    window.location.reload();
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    let data = pullFormData(e);
    console.log(data);
    // make request
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/auth/forgot-password`,
      data: {
        email: data.email,
      },
    };
    const response = await axios(config);

    if (response.status === 200) {
      alert("Success! Go check your email.");
      navigate("/quickbooks/embed/auth");
    }
    console.log(response);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);

    console.log(password, passwordConfirm, code);
    if (!password || !passwordConfirm || password !== passwordConfirm || !code) {
      alert("Error!");
      setLoading(false);
      return;
    }
    // make request

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/auth/reset-password`,
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify({
        code: code,
        password: password,
        passwordConfirmation: passwordConfirm,
      }),
    };

    try {
      // handle request
      const response = await axios(config);

      if (response.data) {
        setLoading(false);
        alert("Success! Please go login.");
        navigate("/quickbooks/embed/auth");
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong...");
      setLoading(false);
    }
  };

  // return those functions
  return {
    user,
    handleLogin,
    handleSignup,
    handleLogout,
    handleForgotPassword,
    findMe,
    handleResetPassword,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    loading,
  };
};
