import React, { useState } from "react";
import { useEffect } from "react";
import "../css/Popup.css";

const Popup = ({ delay, deactivate }) => {
  const [show, setShow] = useState(false);

  //   show once per day
  useEffect(() => {
    if (deactivate) return;
    setTimeout(function () {
      let popup = JSON.parse(localStorage.getItem("bw_popup"));
      if (!popup || new Date(popup?.expires) < new Date()) {
        setShow(true);
      }
    }, delay);
  }, []);

  const handleClose = () => {
    // set cookie to close for one day
    var date = new Date();
    date.setDate(date.getDate() + 1);
    localStorage.setItem("bw_popup", JSON.stringify({ expires: date }));
    setShow(false);
  };

  return (
    <div className={`popup ${!show && "closed"}`}>
      <div className="popup-bg" onClick={handleClose}></div>
      <div className="popup-inner">
        <i className="close-btn fa fa-times  bw-blue-text" onClick={handleClose}></i>

        <h2 className="banks text-center mb-4">Access grants to transform your business!</h2>
        <div className="popup-row">
          <div className="popup-card shadow">
            <img src="/images/Stream_1_Thumbnail.jpeg" className="" />
            <div className="banks text-center mt-2 mb-2">
              <h3 className="banks">Small Businesses</h3>
              <h2 className="bw-blue-text">up to $2,400 in funding</h2>
            </div>
          </div>
          <div className="popup-card shadow">
            <img src="/images/Stream_2_Thumbnail.jpeg" className="" />
            <div className="banks text-center mt-2 mb-2">
              <h3 className="banks">Medium-Sized Businesses</h3>
              <h2 className="bw-blue-text">up to $15,000 in funding</h2>
            </div>
          </div>
        </div>
        <p className="banks text-center mt-0 mb-0">bellwoods web is here to help</p>
        <h3 className="banks text-center mt-0 mb-0">Learn More!</h3>
        <div className="">
          <a href="/contact">
            <i className="fas fa-envelope-square navicon zoom bw-blue-text"></i>
          </a>
          <a href="tel:289-253-7312">
            <i className="fas fa-phone-square navicon zoom bw-blue-text"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Popup;
