import React from "react";

const PAGENUM = 1;
const Page1 = ({ errors, onValuesChange, state }) => {
  const { page, description } = state;
  return (
    <div
      className="quote-page"
      style={
        page === PAGENUM
          ? { maxHeight: "1000vh", height: "auto" }
          : { maxHeight: "0", height: "0" }
      }
    >
      <p className="bodini text-justify">
        This form has 7 pages you'll be asked to fill out. Put in as much or as
        little info as you think is necessary. This is just to give us a better
        idea of what you're looking for. We'll still have a meeting to go over
        some more details. Click the contact button above if you're just looking
        to quickly send us a message. Looking forward to hearing from you!
      </p>
      <hr />
      <div className={`field ${errors[PAGENUM][0] ? "error" : ""}`}>
        <label className="banks" htmlFor="description">
          tell us about the project:
        </label>
        <textarea
          id="description"
          name="description"
          placeholder="breifly describe what you're looking for"
          onChange={(e) => onValuesChange(e, "description", PAGENUM, 0)}
          value={description}
          rows="4"
          className="bodini"
        />
      </div>

      <div className="grouped fields">
        <label className="banks" htmlFor="process">
          which statement is more accurate:
        </label>
        <div className="field">
          <div className="ui radio  bodini">
            <label>
              <input
                type="radio"
                name="process"
                onChange={(e) => onValuesChange(e, "process", PAGENUM, 1)}
              />
              <span className="ml-3">
                i'd like a custom website and more consultation with my
                developer
              </span>
            </label>
          </div>
        </div>

        <div className="field">
          <div className="ui radio bodini">
            <label>
              <input
                type="radio"
                name="process"
                onChange={(e) => onValuesChange(e, "process", PAGENUM, 1)}
              />
              <span className="ml-3">
                i'm looking for a quick design with less meetings and input
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Page1;
