import React, { useState } from "react";

import "../css/Project.css";

const Project = ({ project, showText }) => {
  const [iconState, setIconState] = useState(false);

  return (
    <>
      <div className="project">
        <a target="_blank" rel="noreferrer" href={project.link} onMouseEnter={() => setIconState(true)} onMouseLeave={() => setIconState(false)}>
          <img className="zoom shadow" src={project.image?.data?.attributes?.url} alt="project logo" />
          <i className={`far fa-dot-circle noHover ${iconState ? "" : "hidden"}`} />
        </a>
      </div>
      {showText ? <p className="project-industry banks bw-blue-text">{project.industry}</p> : null}
      {showText ? <p className="project-title banks">{project.title}</p> : null}
    </>
  );
};

export default Project;
