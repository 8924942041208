import React, { useEffect, useState } from "react";
import bellwoods from "../apis/bellwoods";
import "../css/PolicyPage.css";
import qs from "qs";
import "../css/PolicyPage.css";
import Loader from "../components/Loader";
import ReactMarkdown from "react-markdown";
import Page from "../components/Page";
import { useParams } from "react-router-dom";

const PolicyPage = () => {
  const [link, setLink] = useState([]);

  let { id } = useParams();

  useEffect(() => {
    const query = qs.stringify({
      populate: "*",
      filters: {
        slug: {
          $eq: `/${id}`,
        },
      },
    });

    bellwoods
      .get(`/api/policies?${query}`)
      .then((response) => {
        setLink(response?.data?.data?.[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  console.log(link);
  if (!link || link.length === 0) return <Loader />;
  return (
    <Page>
      <div className="ui container bodini policy mh90">
        <ReactMarkdown className="">{link.attributes.content}</ReactMarkdown>
      </div>
    </Page>
  );
};

export default PolicyPage;
