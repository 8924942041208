import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Loader = () => {
  return (
    <div className="spinner">
      <Spinner animation="grow" role="status" variant="primary" size="lg">
        <span className="sr-only">Loading...</span>
      </Spinner>{" "}
      <Spinner animation="grow" role="status" variant="primary" size="lg">
        <span className="sr-only">Loading...</span>
      </Spinner>{" "}
      <Spinner animation="grow" role="status" variant="primary" size="lg">
        <span className="sr-only">Loading...</span>
      </Spinner>
      <p className="banks">
        we're working on it <br /> hold on!
      </p>
      <h3 className="banks">¯\_(ツ)_/¯</h3>
    </div>
  );
};

export default Loader;
