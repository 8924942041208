import React, { useEffect, useState } from "react";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/Services.css";
import Project from "./Project";
import bellwoods from "../apis/bellwoods";
import Loader from "./Loader";
import qs from "qs";
import { Link } from "react-router-dom";
import Partner from "./Partner";

const query = qs.stringify({
  populate: {
    projects: {
      populate: {
        image: {
          populate: "*",
        },
      },
    },
  },
});

const query2 = qs.stringify({
  populate: {
    cover: {
      populate: "*",
    },
  },
});

function OurWork() {
  const [selected, setSelected] = useState(1);
  const [groups, setGroups] = useState([]);

  const [partnerships, setPartnerships] = useState([]);
  useEffect(() => {
    bellwoods
      .get(`/api/featured-projects?${query}`)
      .then((response) => {
        setGroups(response.data.data.sort((a, b) => b.attributes.priority - a.attributes.priority));
      })
      .catch((error) => {
        console.log(error);
      });

    bellwoods
      .get(`/api/partnerships?${query2}`)
      .then((response) => {
        setPartnerships(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const renderCards = () => {
    return groups?.map((element, i) => {
      return (
        <Card key={"work" + i}>
          <Accordion.Toggle as={Card.Header} eventKey={i + 1} className={`${i % 2 ? "oebg" : ""} banks ${i + 1 === selected ? "bw-blue" : "bw-blue"} `} onClick={() => setSelected(i + 1)}>
            {element.attributes.title}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={i + 1}>
            <Card.Body className="bodini text-center" style={{ backgroundColor: "whitesmoke" }}>
              <p style={{ maxWidth: 700, margin: "0 auto" }} className="f16">
                {element.attributes.body}
              </p>
              <Row className="text-center">
                {element.attributes?.projects?.data?.map((proj, i) => {
                  return (
                    <Col key={"proj" + proj.id} xs={{ span: 12, offset: 0 }} sm={{ span: 4, offset: 0 }}>
                      <Project project={proj.attributes} showText={false} />
                    </Col>
                  );
                })}
              </Row>
              <div className="btncont">
                <Link className="ui button seemore shadow banks large" to="/portfolio">
                  See More
                </Link>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    });
  };

  if (groups.length === 0) {
    return <Loader />;
  }
  return (
    <div className="services train-bg mh50">
      <div className="services-overlay mh50">
        {/* <Container> */}
        <Row>
          <Col xs={{ span: 12, offset: 0 }} lg={{ span: 8, offset: 2 }}>
            <h2 className="banks">Our Work.</h2>
            <div className="portflio shadow">
              <p className="bodini f16" style={{}}>
                We take immense pride in our consulting and development services. For us, a project is not considered successful until we have achieved tangible and measurable benefits for our clients
                and their customers. Here are some examples of our recent projects that showcase our commitment to delivering impactful results.
              </p>
              <Accordion defaultActiveKey="1">{renderCards()}</Accordion>
            </div>
          </Col>

          {partnerships?.map((el, i) => {
            return <Partner key={"partner" + i} data={el} i={i} />;
          })}
        </Row>
        {/* </Container> */}
        <div className="btncont">
          <Link className="ui button seemore shadow banks large" to="/portfolio">
            See More
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OurWork;
