import React, { useState } from "react";

import { useEffect } from "react";
import "../css/Reviews.css";

// import bellwoods from "../apis/bellwoods";

function timeout() {
  return new Promise((resolve) => setTimeout(resolve, 1500));
}

const Reviews = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    timeout().then(() => {
      setLoading(false);
    });
  }, []);

  if (isLoading) return null;
  return (
    <div className="reviews-container">
      <div className="elfsight-app-409cef59-47c8-48ea-8d6d-00f7c2b57bff"></div>
      <div className="cover"></div>
      {/* <Row>
        <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          <ReviewCarousel />
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>

          <a href="https://g.page/r/CeAz0K_yQHlAEAI/review" target="_blank" className="ui bw-blue google button banks google-button">
            <i className="google icon" />
            Leave a Review With Google
          </a>
        </Col>
      </Row> */}
    </div>
  );
};

// function ReviewCarousel() {
//   const [reviews, setReviews] = useState();

//   useEffect(() => {
//     // get projects
//     bellwoods
//       .get("/api/reviews")
//       .then((response) => {
//         setReviews(response.data.data);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }, []);

//   if (!reviews) return null;

//   return (
//     <Carousel className="reviews h50 bodini shadow">
//       {reviews?.map((review, i) => {
//         if (!review.attributes.approved) return null;
//         return (
//           <Carousel.Item key={i}>
//             <div className="overlay"></div>

//             <img className="h50" src={`./images/carousel/${(i % 6) + 1}.png`} alt="First slide" />
//             <Carousel.Caption>
//               <div className="carouselContent">
//                 <p className="review-content">{review.attributes.content}</p>
//                 <Stars stars={review.attributes.stars} />
//                 <span className="review-info">
//                   {review.attributes.name}
//                   <span className="d-none d-md-inline">{` - `}</span>
//                   <br className="d-md-none" />
//                   {review.attributes.company}
//                 </span>
//               </div>
//             </Carousel.Caption>
//           </Carousel.Item>
//         );
//       })}
//     </Carousel>
//   );
// }

export default Reviews;
