import Navigation from "./navigation/Navigation";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { useEffect } from "react";

function App() {
  // useEffect(() => {
  //   AOS.init();
  //   AOS.refresh();
  // }, []);

  return <Navigation />;
}

export default App;
